import { ActionIcon, Avatar, Button, Flex, Text, TextInput, Tooltip } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconEdit, IconFileText } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PriceGroupApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { PageFilter } from "../layout";
import { usePermission } from "../permission";
import { ColumnType, Table } from "../table";
import { Tag } from "../tags";

export function StandartPriceHistoryList() {
  const tableRef = React.useRef<any>(null);
  const [query, setQuery] = useDebouncedState("", 500);
  const navigate = useNavigate();
  const ERP_PG = usePermission("ERP_PG");
  const { priceGroupStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "details":
          navigate(
            {
              pathname: `/policy/${item.id}/details`,
            },
            {
              state: {
                type: "STANDARD",
              },
            },
          );
          break;
        case "edit":
          navigate(
            {
              pathname: `/policy/${item.id}/edit`,
            },
            {
              state: {
                type: "STANDARD",
              },
            },
          );
          break;
      }
    },
    priceGroupStatus,
  });

  return (
    <Flex direction="column" gap="md">
      <PageFilter
        right={[
          <Button
            hidden={!ERP_PG.isCreate}
            onClick={() => {
              navigate("/policy/change");
            }}
            key={0}>
            Үнэ өөрчлөх
          </Button>,
        ]}
        left={[<TextInput w={"220px"} onChange={(e) => setQuery(e.currentTarget.value)} key={0} placeholder="Хайлт хийх" />]}
      />
      {ERP_PG.isView ? (
        <Table
          minWidth={1880}
          ref={tableRef}
          name="PriceGroupApi.list"
          columns={columns}
          filters={{ query: query, type: "GROUP" }}
          loadData={(data) => PriceGroupApi.list(data!)}
        />
      ) : (
        ERP_PG.accessDenied()
      )}
    </Flex>
  );
}

const useHeader = ({ onClick, priceGroupStatus }: { onClick: (key: string, record: any) => void; priceGroupStatus: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <ActionIcon variant="light" color="" onClick={() => onClick("details", record)}>
              <IconFileText />
            </ActionIcon>
          </Tooltip>
          {record.priceGroupStatus === "DRAFT" && (
            <Tooltip label="Засах" position="bottom">
              <ActionIcon variant="light" color="" onClick={() => onClick("edit", record)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Үнийн код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Үнэ статус",
    dataIndex: "priceGroupStatus",
    render: (record) =>
      Tag(
        priceGroupStatus.find((item: IReference) => item.code === record.priceGroupStatus)?.color ?? "gray",
        (priceGroupStatus.find((item: IReference) => item.code === record.priceGroupStatus)?.name || record.priceGroupStatus) ?? "-",
      ),
  },
  {
    title: "Үнийн өөрчлөлт нэр",
    sorter: true,
    dataIndex: "s",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Өөрчлөлтийн тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.description}
      </Text>
    ),
  },
  {
    title: "Барааны тоо",
    sorter: true,
    dataIndex: "regUserId",
    render: (record) => `-`,
  },
  {
    title: "Эхлэх огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.startDate ? dateTimeFormat(record.startDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Эхэлсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.startedDate ? dateTimeFormat(record.startedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.createdAt ? dateTimeFormat(record.createdAt) : "-"}
      </Text>
    ),
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) =>
      record?.regUser ? (
        <Flex align="center" gap={10}>
          <Avatar src={record?.regUser?.avatar ?? ""} />
          <Text size="sm">
            {record?.regUser?.lastName ? record?.regUser?.lastName[0] + "." : ""}
            {record?.regUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
];
