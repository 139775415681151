import { ActionIcon, Avatar, Badge, Box, Button, Center, Checkbox, CloseButton, Drawer, Flex, Text } from "@mantine/core";
import { IconCreditCard, IconUser } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NetworkApi } from "../../apis";
import { FormLayout, PageLayout } from "../../components/layout";
import { useScreenDetector } from "../../components/screen-detector";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { NetworkSetPriceForm } from "./network-set-price";

export const NetworkPriceList = () => {
  const breadcrumbs = useBreadcrumb();
  const { categories = [], itemTypes = [], itemStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = useState<any[]>([]);
  const refTable = useRef<ITableRef>(null);
  const [select, setSelect] = useState<any[]>([]);
  const { isMobile } = useScreenDetector();
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = useHeader({
    itemStatus,
    itemTypes,
    categories,
    select,
    setSelect,
    onClick: async (key, record) => {
      switch (key) {
        case "check":
          break;
        case "edit":
          break;
        default:
          break;
      }
    },
  });
  const reload = async () => {
    refTable.current?.reload();
  };

  // const downloadExcel = async () => {
  //   setIsLoading(true);
  //   try {
  //     let res = await NetworkApi.list({
  //       filter: {
  //         query: "",
  //         excel: "true",
  //       },
  //       offset: {
  //         page: 1,
  //         limit: 1,
  //       },
  //     });

  //     if (res?.excelUrl) {
  //       message.success("Хүсэлт амжилттай");
  //       window.open(`${res.excelUrl}`);
  //     }
  //   } catch (error: any) {
  //     message.error(error.message || "Хүсэлт амжилтгүй!");
  //   }
  //   setIsLoading(false);
  // };

  return (
    <PageLayout
      title="Харилцагчийн үнэ тохиргоо"
      subTitle="Харилцагчдад тохируулсан үнийн бодлого"
      breadcrumb={breadcrumbs}
      extra={[
        isMobile ? (
          <ActionIcon disabled={select.length === 0} onClick={() => setAction(["set_price"])} size={36} key={0} color="" variant="filled">
            <IconCreditCard />
          </ActionIcon>
        ) : (
          <Button disabled={select.length === 0} onClick={() => setAction(["set_price"])} size="sm" key={1}>
            Үнэ тохируулах
          </Button>
        ),
      ]}>
      <Table
        minWidth={"max-content"}
        ref={refTable}
        name={`erp.network.list`}
        columns={columns}
        filters={{
          query: "",
        }}
        loadData={(data) => NetworkApi.list(data!)}
      />

      <Drawer
        position="right"
        size={"90rem"}
        opened={action[0] === "set_price"}
        onClose={() => setAction([])}
        title={false}
        withCloseButton={false}
        padding={0}>
        <Box p={"lg"} style={{ overflowY: "auto", height: "calc(100vh)", overflowX: "hidden" }}>
          <FormLayout
            title={
              <Text fw={700} c="violet.9">
                ХАРИЛЦАГЧИД ҮНИЙН БҮЛЭГ ТОХИРУУЛАХ
              </Text>
            }
            extra={[<CloseButton onClick={() => setAction([])} key={9} />]}>
            <NetworkSetPriceForm select={select} setSelect={setSelect} onClose={() => setAction([])} action={action} reload={reload} />
          </FormLayout>
        </Box>
      </Drawer>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  itemStatus,
  itemTypes,
  categories,
  select,
  setSelect,
}: {
  onClick: (key: string, record: IItem | any) => void;
  itemStatus: IReference[];
  itemTypes: any[];
  categories: any[];
  select: any[];
  setSelect: (e: any) => void;
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: <></>,
    actionTitle: (data) => {
      return (
        <Center w={20}>
          <Checkbox
            checked={select?.length === (data?.rows?.length || 0) && select.every((element: any, index: number) => element.id === data?.rows[index]?.id)}
            onChange={() => {
              if (select?.length === (data?.rows?.length || 0) && select.every((element: any, index: number) => element.id === data?.rows[index]?.id))
                setSelect([]);
              else setSelect(data?.rows || []);
            }}
          />
        </Center>
      );
    },
    render: (record, index) => (
      <Center w={20}>
        <Checkbox
          onChange={() => {
            if (select.some((item: any) => item.id === record.id)) {
              setSelect(select.filter((item: any) => item.id !== record.id));
            } else setSelect([...select, record]);
          }}
          checked={select.some((item: any) => item.id === record.id)}
        />
      </Center>
    ),
  },
  {
    title: "Партнер нэр",
    dataIndex: "image",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.partner?.businessName || "-"}
        </Text>
        <Text size="sm" c="violet" w="max-content">
          #{record?.partner?.refCode || "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.regNumber || "-"}
      </Text>
    ),
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <div>
        <Text size="sm" w="max-content">
          {record?.profileName || "-"}
        </Text>
        <Text size="sm" w="max-content" c="violet">
          #{record?.refCode || "-"}
        </Text>
      </div>
    ),
  },
  {
    title: "Менежер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return record.coBusinessStaff ? (
        <Flex gap={10} align="center">
          <Avatar src={record.coBusinessStaff?.avatar} />
          <div>
            <Text tt={"capitalize"} size="sm" w="max-content">
              {record?.coBusinessStaff?.lastName ? record?.coBusinessStaff?.lastName[0] + "." : ""} {record?.coBusinessStaff?.firstName || "-"}
            </Text>
            <Text size="sm" c="violet" w="max-content">
              {record?.coBusinessStaff?.email || "-"}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Санхүү",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      if (record.coBusinessFinStaffs.length === 1)
        return (
          <Flex align="center" gap={10}>
            <Avatar src={record.coBusinessFinStaffs[0]?.avatar} />
            <div>
              <Text tt={"capitalize"} size="sm" w="max-content">
                {record?.coBusinessFinStaffs[0]?.lastName ? record?.coBusinessFinStaffs[0]?.lastName[0] + "." : ""}
                {record?.coBusinessFinStaffs[0]?.firstName}
              </Text>
              <Text size="sm" c="violet" w="max-content">
                {record?.coBusinessFinStaffs[0]?.email || "-"}
              </Text>
            </div>
          </Flex>
        );
      return (
        <Flex align="center" gap={10}>
          <IconUser />
          <Text size="sm">/{record.coBusinessFinStaffs.length}</Text>
        </Flex>
      );
    },
  },
  {
    title: "Үнэ тохиргоо",
    sorter: true,
    dataIndex: "category",
    render: (record) => (
      <Badge variant="outline" color={record.isPriceSet ? "green.5" : "gray.5"}>
        {record.isPriceSet ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Тохируулсан үнэ",
    sorter: true,
    dataIndex: "classificationId",
    render: (record) =>
      record.isPriceSet ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.priceGroup ? record.priceGroup?.name : "-"}
          </Text>
          <Text size="sm" w="max-content">
            {record?.priceGroup ? record.priceGroup?.description : ""}
          </Text>
        </div>
      ) : (
        "Стандарт үнэ"
      ),
  },
  {
    title: "Эхлэх огноо",
    sorter: true,
    dataIndex: "priceStartDate",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.priceStartDate ? dateTimeFormat(record.priceStartDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Дуусах огноо",
    sorter: true,
    dataIndex: "priceEndDate",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.priceEndDate ? dateTimeFormat(record.priceEndDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Тохируулсан ажилтан",
    sorter: true,
    dataIndex: "priceSetUser",
    render: (record) =>
      record?.priceSetUser ? (
        <Flex align="center" gap={10}>
          <Avatar src={record.priceSetUser?.avatar} />
          <Text size="sm">
            {record?.priceSetUser?.lastName ? record?.priceSetUser?.lastName[0] + "." : ""}
            {record?.priceSetUser?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Тохируулсан огноо",
    sorter: true,
    dataIndex: "priceSetDate",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.priceSetDate ? dateTimeFormat(record.priceSetDate) : "-"}
      </Text>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Харилцагчийн үнэ тохиргоо",
  },
];
